@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: -webkit-fill-available;
}

::-webkit-scrollbar {
  display: none;
}

.test {
  min-height: 100vh;            /* fall-back */
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
}

@layer components {
  .pnl-colors {
    @apply rounded-sm bg-stone-100 
     text-neutral-900 shadow-md
     shadow-stone-400 dark:bg-neutral-800
     dark:text-white dark:shadow-slate-800;
  }

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes appearFromRight {
    0% {
      /* transform: translateX(100%) scale(0.5); */
      transform: scale(0.5);
      opacity: 0;
    }
    50% {
      /* transform: scale(1); */
      transform: scale(1);
      opacity: 100;
    }
    100% {
      /* transform: translateX(0%) scale(1); */

      opacity: 100;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100;
    }
  }

  .slideInFromLeft {
    animation: 2s ease-out 0s 1 slideInFromLeft;
  }

  .appearFromRight {
    animation: 0.5s cubic-bezier(0, 1.7, 1, 1.7) 0s 1 appearFromRight;
    animation-fill-mode: backwards;
  }

  .fadeIn {
    animation: 1s ease-in-out 0s 1 fadeIn;
    animation-fill-mode: backwards;
  }
}

.highlight-transparent {
  -webkit-tap-highlight-color: transparent;
}

@layer utilities {
  .animation-delay-0 {
    animation-delay: 0ms;
  }

  .animation-delay-300 {
    animation-delay: 300ms;
  }

  .animation-delay-600 {
    animation-delay: 600ms;
  }

  .animation-delay-900 {
    animation-delay: 900ms;
  }

  .animation-delay-1200 {
    animation-delay: 1200ms;
  }

  .animation-delay-1500 {
    animation-delay: 1500ms;
  }

  .animation-delay-1800 {
    animation-delay: 1800ms;
  }

  .animation-delay-2100 {
    animation-delay: 2100ms;
  }

  .animation-delay-2400 {
    animation-delay: 2400ms;
  }

  .animation-delay-2700 {
    animation-delay: 2700ms;
  }

  .animation-delay-3000 {
    animation-delay: 3000ms;
  }
}

.w-108 {
  width: 32rem;
}

.pulse {
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(217, 119, 6, 0.9);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 8px rgba(217, 119, 6, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(217, 119, 6, 0);
	}
}

.min-h-screen-more {
  min-height: 120vh;
}

[data-inviewport] { /* THIS DEMO ONLY */
  
}

/* inViewport */

[data-inviewport="scale-in"] { 
  transition: 2s;
  transform: scale(0.1);
}
[data-inviewport="scale-in"].is-inViewport { 
  transform: scale(1);
}

[data-inviewport="fade-rotate"] { 
  transition: 2s;
  opacity: 0;
}
[data-inviewport="fade-rotate"].is-inViewport { 
  transform: rotate(180deg);
  opacity: 1;
}

.text-outline {
  text-shadow: -1px -1px 0 rgb(0, 0, 0), 1px -1px 0 rgb(0, 0, 0), -1px 1px 0 rgb(0, 0, 0), 1px 1px 0 rgb(0, 0, 0);
}

.text-outline-none {
  text-shadow: none;
}

.idx-relative {
  position: absolute;
  top: 80%;
  left: 50;
}

.idx-loader-bg {
  position: relative;
  top: 0;
  left: 0;
  width: 15rem;
  height: 0.3rem;
  background-color: #9c907d;
  border-radius: 1px;
}

.idx-loader {
  position: relative;
  top: 0;
  left: 0;
  width: 8rem;
  height: 0.3rem;
  border-radius: 1px;
  background-color: #e97d2d;
  -webkit-animation: spin 2s linear infinite alternate;
  /* Safari */
  animation: spin 2s linear infinite alternate;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-padding-left: rotate(0deg);
  }

  100% {
    -webkit-padding-left: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    margin-left: 0rem;
  }

  100% {
    margin-left: 7rem;
  }
}